import { twJoin } from 'tailwind-merge';

import Text from 'shopper/components/Text';

import FOOTER from 'constants/footer';

const ITEMS = [FOOTER.INFO, FOOTER.ADDRESS, FOOTER.COPYRIGHT];

const FooterBottom = () => (
  <div className="flex w-full flex-col flex-wrap items-center justify-center py-4 md:max-w-[40rem] md:flex-row lg:max-w-2xl">
    {ITEMS.map((item, index) => (
      <Text
        key={item}
        className={twJoin(
          'text-center md:text-left',
          index !== 0 && 'mt-4 md:mt-0'
        )}
        size="size3"
      >
        {item}{' '}
      </Text>
    ))}
  </div>
);

export default FooterBottom;
